import { createContext } from "react";
import squareApi from "../../lib/squareApi";
import reducers from "./reducers";
import useReducerWithThunk from "../reducerMiddleware";
import { useContext } from "react";

export const CartContext = createContext();

const types = {
  SET_CART: "SET_CART",
  SET_ORDER_CART: "SET_ORDER_CART",
  ADD_CART: "ADD_CART",
  DISPLAY_CART: "DISPLAY_CART",
  SET_LOADING: "SET_LOADING",
};

const actions = {
  setCart: (cart) => ({
    type: types.SET_CART,
    payload: cart,
  }),
  setOrderAndCart: (order) => ({
    type: types.SET_ORDER_CART,
    payload: order,
  }),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, payload: isLoading }),
  toggleCart: () => ({ type: types.DISPLAY_CART }),
};

const cartAPI = squareApi.cart;

const fetchCartItems = () => async (dispatch) => {
  const squareData = await cartAPI.fetchCartItems();
  if (!squareData) return;
  dispatch(actions.setOrderAndCart(squareData));
};

const addCartItem = (id, quantity) => async (dispatch, state) => {
  const { cart } = state;
  const existingLineItem = cart.find(({ id: lineItemId }) => lineItemId === id);
  if (existingLineItem) {
    const lineItems = [
      { uid: existingLineItem.uid, quantity: String(quantity) },
    ];
    dispatch(adjustCartItems(lineItems));
  } else {
    dispatch(upsertCartItem(id, quantity));
  }
};

const upsertCartItem = (id, quantity) => async (dispatch, state) => {
  dispatch(actions.setLoading(true));
  const squareData = await cartAPI.upsertCartItem(
    {
      catalogObjectId: id,
      quantity: `${quantity}`,
    },
    state.order?.orderId
  );
  if (squareData?.order) {
    dispatch(actions.setOrderAndCart(squareData));
  } else {
    dispatch(actions.setCart(squareData));
  }
  dispatch(actions.setLoading(false));
};

const adjustCartItems = (lineItems, deletions) => async (dispatch, state) => {
  dispatch(actions.setLoading(true));
  const squareData = await cartAPI.adjustCartItems(
    lineItems,
    deletions,
    state.order?.orderId
  );
  dispatch(actions.setCart(squareData));
  dispatch(actions.setLoading(false));
};

const checkout = () => async (dispatch, state) => {
  const result = await cartAPI.adjustShippingServiceCharge(state.order.orderId);
  // console.log(result);
  window.open(state.order.payLink, "_top");
};

const initialState = {
  cart: [],
  order: {},
  displayCart: false,
  netAmounts: {},
  isLoading: false,
};

const cartReducer = (state, { type, payload }) => {
  switch (type) {
    case types.ADD_CART:
      return reducers.addToCart(state, payload);
    case types.SET_CART:
      return reducers.setCart(state, payload);
    case types.SET_ORDER_CART:
      return reducers.setOrderAndCart(state, payload);
    case types.SET_LOADING:
      return reducers.setLoading(state, payload);
    case types.DISPLAY_CART:
      return reducers.handleDisplyCart(state);
    default:
      return state;
  }
};
const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducerWithThunk(cartReducer, initialState);
  const value = {
    ...state,
    dispatch,
  };
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
export const useCartContext = () => useContext(CartContext);
export const { toggleCart } = actions;
export { fetchCartItems, addCartItem, adjustCartItems, checkout };
export default CartProvider;
