import axios from "axios";
import { useEffect, useState } from "react";
import squareApi from "./lib/squareApi";
const axios_instance = axios.create({
  baseURL: process.env.REACT_APP_KOANA_SQUARE_API,
});

export const useInventoryUpdate = (cart, checkout) => {
  const [inventories, setInventories] = useState([]);
  const [isCheckout, setIsCheckout] = useState(false);

  useEffect(() => {
    if (cart.length === 0) return;
    const controller = new AbortController();
    const signal = controller.signal;
    const runAxios = async () => {
      try {
        const result = await squareApi.cart.fetchInventories(cart, signal);
        setInventories([...result]);
        if (isCheckout) {
          const isStockError = cart.some((cartItem) => {
            const { inventory } = result.find(
              ({ id: invId }) => invId === cartItem.id
            );
            return inventory < cartItem.quantity;
          });
          if (isStockError) {
            setIsCheckout(false);
          } else {
            checkout(cart);
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
      }
    };
    runAxios();

    return () => controller.abort();
  }, [cart, isCheckout]);

  return [inventories, setIsCheckout];
};

export default axios_instance;
