import { GetIcon } from "./GetIcon";
import { useNavigate } from "react-router-dom";
import { koana_logo } from "../../assets/images/icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import {
  useCartContext,
  toggleCart,
} from "../../context/CartContext/CartContext";

const RegularNavBar = () => {
  const { dispatch } = useCartContext();
  const navigate = useNavigate();

  const handleDisplayCart = () => {
    dispatch(toggleCart());
  };

  const koanaIcon = (
    <div key={"koanaIcon"} className="regularKoanaIcon">
      <img
        onClick={() => {
          navigate(`/`);
        }}
        src={koana_logo}
        alt="logo"
      />
    </div>
  );

  //const tradNavItems = ["EVENTS", "SHOP", "ABOUT"].map((item, idx) => (
    // EVENTS removed below
  const tradNavItems = ["SHOP", "ABOUT"].map((item, idx) => (
  <div
      onClick={() => navigate(`/${item}`)}
      key={`${idx}${item}`}
      className={item !== "EVENTS" ? "regularTradNavItems" : "regularTradNavItemsEvents"}
    >
      {item}
    </div>
  ));

  return (
    <div className="regularNavContainer">
      {koanaIcon}
      <div className="regularNavItems">
        {tradNavItems}
        <GetIcon
          iconsData={[
            [faCartArrowDown, () => handleDisplayCart()],
            [
              faInstagram,
              () =>
                window.open("https://www.instagram.com/koanahawaii/", "_blank"),
            ],
          ]}
        />
      </div>
    </div>
  );
};

export default RegularNavBar;
