import styles from "./QuantitySelector.module.scss";

const QuantitySelector = ({
  handleIncrement,
  handeDecrement,
  quantity,
  size = "large",
}) => {
  return (
    <div
      className={size === "small" ? styles.smallContainer : styles.container}
    >
      <div
        className={styles.decrement}
        onClick={() => {
          handeDecrement();
        }}
      >
        <div className={styles.minus}></div>
      </div>
      <div className={styles.quantityWindow}>{quantity}</div>

      <div
        className={styles.increment}
        onClick={() => {
          handleIncrement();
        }}
      >
        <div className={styles.plusHorizontal}></div>
        <div className={styles.plusVertical}></div>
      </div>
    </div>
  );
};
export default QuantitySelector;
