const addToCart = (state, lineItem) => {
  if (lineItem === "") return { cart: [] };
  const { id, quantity } = lineItem;
  const cart = state.cart;
  const idxOfItem = cart.reduce((foundIdx, item, idx) => {
    if (item.id === id) {
      return idx;
    }
    return foundIdx;
  }, -1);

  if (idxOfItem !== -1) {
    cart[idxOfItem].quantity = quantity;
    return { cart: cart };
  }
  return { cart: [...cart, lineItem] };
};

const setCart = (state, cart) => {
  if (cart === "") return { ...state, cart: [] };
  const _netAmounts = Object.entries(cart.netAmounts).reduce(
    (obj, [key, value]) => {
      return { ...obj, [key]: value.amount / 100 };
    },
    {}
  );
  const cartItems = cart.items.map((item) => {
    return {
      ...item,
      price: item.price / 100,
    };
  });
  return {
    ...state,
    deletions: [],
    cart: cartItems,
    netAmounts: _netAmounts,
  };
};

const setOrderAndCart = (state, payload) => {
  const { order, cart } = payload;
  const setCartState = setCart(state, cart);
  const { orderId, link } = order;
  return {
    ...setCartState,
    order: { orderId, payLink: link },
  };
};

const handleDisplyCart = (state) => {
  return { ...state, displayCart: !state.displayCart };
};
const setLoading = (state, isLoading) => {
  return { ...state, isLoading };
};

const reducers = {
  addToCart,
  setOrderAndCart,
  setCart,
  setLoading,
  handleDisplyCart,
};

export default reducers;
