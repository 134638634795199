import axios from "../axios.js";

const options = {
  withCredentials: true,
  credentials: "include",
};
//Items API
const items = {
  fetchItems: async () => {
    try {
      const res = await axios.get("/catalog");
      return res.data;
    } catch (error) {
      console.error(error);
    }
  },
};
//Cart(order) API
const cart = {
  //ignore this endpoint in express app
  createPaymentLink: async (lineItems) => {
    try {
      const response = await axios.post("/order/paymentLink", { lineItems });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  fetchCartItems: async () => {
    try {
      const res = await axios.get("/order", options);
      return res.data;
    } catch (error) {
      throw console.error(error);
    }
  },
  upsertCartItem: async (lineItem, orderId) => {
    try {
      const res = await axios.put("/order", { lineItem, orderId }, options);
      return res.data;
    } catch (error) {
      throw console.error(error);
    }
  },
  adjustCartItems: async (lineItems, deletions, orderId) => {
    if (!deletions) {
      deletions = [];
    }
    try {
      const res = await axios.put(
        "/order/quantities",
        { lineItems, deletions, orderId },
        options
      );
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  fetchInventories: async (cart, signal) => {
    try {
      const catalogObjectIds = cart.map(({ id }) => id);

      const res = await axios.post(
        "/catalog/inventories",
        { catalogObjectIds },
        {
          signal,
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  confirmation: async (orderId) => {
    try {
      const response = await axios.get(
        `/order/confirmation/?orderId=${orderId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  cancelCart: async () => {
    try {
      await axios.put("/order/cancel", {}, options);
    } catch (error) {
      console.error(error);
    }
  },
  adjustShippingServiceCharge: async (orderId) => {
    try {
      await axios.put("/order/adjustShipping", { orderId }, options);
    } catch (error) {
      console.error(error.toJSON());
    }
  },
};

const squareApi = { cart, items };
export default squareApi;
