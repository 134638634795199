// import { imagesData } from "../../data/imagesData";
import FocusModalForm from "./FocusModalForm";
// import koana_logo_outline from '../../layout/NavMenu/koana_logo_outline.png';
import "./focusModal.css";
import { useContext } from "react";
import { StoreItemContext } from "../../context/ItemsContext/ItemsContext";

const FocusModal = ({ handleModalFocus, id }) => {
  const { storeItems } = useContext(StoreItemContext);

  const item = storeItems.find((item) => item.id === id);
  if (!item) return null;

  return (
    <div key={"modal".concat(item.id)} className="modalBackground">
      <div className="modalCloseButton" onClick={() => handleModalFocus(false)}>
        <div className="modalCloseButtonLeftLine"></div>
        <div className="modalCloseButtonRightLine"></div>
      </div>
      <div className="modalBorder">
        <img src={item.image} alt={item.name}></img>
        <div className="boxDetails">
          <div className="boxText">
            <div className="boxName">
              {item.name ? item.name.toUpperCase() : "NAME UNAVAILABLE"}
            </div>
            <div className="boxDesc">
              {item.desc
                ? item.desc.replace(/\<br \/\>/g, "").toUpperCase()
                : item.name
                ? item.name.toUpperCase()
                : null}
            </div>
            <div className="boxPriceWeight">
              <div className="boxPrice">
                {item.price ? `$${item.price}` : "PRICE UNAVAILABLE"}
              </div>
              <div className="boxWeight">
                {item.weight ? ` ${item.weight}${item.unit}` : null}
              </div>
            </div>
          </div>
          <FocusModalForm id={id} handleModalFocus={handleModalFocus} />
        </div>
      </div>
    </div>
  );
};

export default FocusModal;
