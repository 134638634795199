import koanaIcon from "./tempAssets/koana_icon_new_bright.png";
import "./orderConfirmationPage.css";
import { formatPrice } from "../../utils/priceFormat";

const OrderConfirmationPageComponents = ({ orderData }) => {
  const { orderId, lineItems, shipmentDetails, netAmounts, createdAt } =
    orderData;

  const { address, firstName, lastName, emailAddress, phoneNumber } =
    shipmentDetails;

  const addressLocal = (data) => {
    return data.replaceAll(/,/g, "").replace(/US/, "");
  };

  const orderNumberShort = (data) => {
    return data.split("").slice(-10).join("");
  };

  const accessUserData = (data) => {
    const userData = {
      address: addressLocal(address),
      name: firstName.concat(" ").concat(lastName),
      email: emailAddress,
      orderID: orderNumberShort(orderId),
      currentDate: String(createdAt).split(" ").slice(0, 4).join(" "),
    };
    return userData[data];
  };

  const subtotal = lineItems.reduce((subtotal, { basePriceMoney }) => {
    return subtotal + +basePriceMoney;
  }, 0);

  const accessAmounts = (data) => {
    const { serviceChargeMoney, taxMoney, discountMoney, totalMoney } =
      netAmounts;

    const priceBreakdownData = {
      subtotal: subtotal.toFixed(2),
      shipping: formatPrice(serviceChargeMoney.amount),
      tax: formatPrice(taxMoney.amount),
      discount: formatPrice(discountMoney.amount),
      grandTotal: formatPrice(totalMoney.amount),
    };
    return priceBreakdownData[data];
  };

  const OrderConfirmationPageSections = {
    ConfirmationMessage: () => (
      <div className="orderConfirmationMessageContainer">
        <div className="orderKoanaIconStamp">
          <img src={koanaIcon} alt="logo" />
        </div>
        <div className="orderConfirmationMessageBanner">
          Order confirmation
          <span style={{ fontSize: "2rem" }}> ✓</span>
        </div>
        <div className="orderConfirmationMessageThankyou">
          Your order has been confirmed and will be shipping soon.
        </div>
      </div>
    ),
    OrderUserData: () => (
      <div className="orderUserDataContainer">
        <div className="orderUserDataRow">
          <div className="orderUserDataCategory">Order Date</div>
          <div className="orderUserDataCategoryValue">
            {accessUserData("currentDate")}
          </div>
        </div>
        <div className="orderUserDataRow">
          <div className="orderUserDataCategory">Customer</div>
          <div className="orderUserDataCategoryValue">
            {accessUserData("name")}
          </div>
        </div>
        <div className="orderUserDataRow">
          <div className="orderUserDataCategory">Email</div>
          <div className="orderUserDataCategoryValue">
            {accessUserData("email")}
          </div>
        </div>
        <div className="orderUserDataRow">
          <div className="orderUserDataCategory">Order Number</div>
          <div className="orderUserDataCategoryValue" id="orderID">
            {accessUserData("orderID")}
          </div>
        </div>
        <div className="orderUserDataRow">
          <div className="orderUserDataCategory">Address</div>
          <div className="orderUserDataCategoryValue" id="address">
            {accessUserData("address")}
          </div>
        </div>
      </div>
    ),
    OrderItems: () =>
      lineItems.map((item, idx) => {
        return (
          <div key={idx} className="orderItemContainer">
            <img className="orderItemImage" src={item.image} alt="item" />

            <div className="orderItemDetails">
              <div className="orderItemImageNameSetSmall">
                <img
                  className="orderItemImageSmall"
                  src={item.image}
                  alt={item.name}
                />
                <div className="orderItemName">{item.name}</div>
              </div>
              <div className="orderItemMath">
                <div className="orderItemQty">
                  {"Qty: ".concat(item.quantity)}
                </div>
                <div className="orderItemPrice">
                  {"$".concat(item.basePriceMoney)}
                </div>
              </div>
            </div>
          </div>
        );
      }),
    PriceBreakdown: () => (
      <div className="orderPriceBreakdownContainer">
        <div className="orderPriceBreakdownRow">
          <div className="orderPriceBreakdownCategory">Subtotal</div>
          <div className="orderPriceBreakdownCategoryValue">
            {"$".concat(accessAmounts("subtotal"))}
          </div>
        </div>
        <div className="orderPriceBreakdownRow">
          <div className="orderPriceBreakdownCategory">Shipping</div>
          <div className="orderPriceBreakdownCategoryValue">
            {"$".concat(accessAmounts("shipping"))}
          </div>
        </div>
        <div className="orderPriceBreakdownRow">
          <div className="orderPriceBreakdownCategory">Taxes</div>
          <div className="orderPriceBreakdownCategoryValue">
            {"$".concat(accessAmounts("tax"))}
          </div>
        </div>
        {accessAmounts("discount") > 0 ? (
          <div className="orderPriceBreakdownRow">
            <div className="orderPriceBreakdownCategory">Discount</div>
            <div
              className="orderPriceBreakdownCategoryValue"
              style={{ color: "#51cf85" }}
            >
              {"$".concat(accessAmounts("discount"))}
            </div>
          </div>
        ) : null}
      </div>
    ),
    Total: () => (
      <div className="orderTotalContainer">
        <div className="orderTotalRow">
          <div className="orderTotalCategory">Total</div>
          <div className="orderTotalCategoryValue">
            {"$".concat(accessAmounts("grandTotal"))}
          </div>
        </div>
      </div>
    ),
    Contact: () => (
      <>
        <div className="orderContactContainer">
          <div className="orderContactRow">
            Please check your email for confirmation. We hope you enjoy your
            high-quality purchase. Thank you{" "}
            {`${accessUserData("name").split(" ")[0]}.`}
          </div>
          <div className="orderContactRow">
            Aloha,
            <div className="orderContactKoana">Koana</div>
          </div>
        </div>
        <div className="orderContactPhone">
          telephone:{" "}
          <a className="orderContactPhoneWide" href="tel:808-209-4432">
            808-209-4432
          </a>
          <a className="orderContactPhoneNarrow" href="tel:808-209-4432">
            808-209-4432
          </a>
        </div>
      </>
    ),
  };

  const {
    ConfirmationMessage,
    OrderUserData,
    OrderItems,
    PriceBreakdown,
    Total,
    Contact,
  } = OrderConfirmationPageSections;
  return (
    <>
      <ConfirmationMessage />
      <OrderUserData />
      <OrderItems />
      <PriceBreakdown />
      <Total />
      <Contact />
    </>
  );
};

export default OrderConfirmationPageComponents;
