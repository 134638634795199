import { useRef, useState } from 'react';
import { Cloudinary } from "@cloudinary/url-gen";

export const CloudinaryVideo = ({ cloudName, instance, styles }) => {

  const [toggle, setToggle] = useState(() => true);
  const vidRef = useRef(null);
  
  const handlePlayVideo = () => {
    setToggle(() => !toggle)
    toggle ? vidRef.current.pause() : vidRef.current.play();
  }
  
  // Create and configure your Cloudinary instance.
  const cld = new Cloudinary({
    cloud: {
      cloudName,
    },
  });

  // creating an accessable instance of the video
  const introVid = cld.video(instance);
  // adding an 'auto-quality' filter to adjust dependent on network performance
  introVid.quality("auto");

  // creating a URL from the instance
  const introVidURL = introVid.toURL();

  // adding to JSX surrounded with the styling CSS class 'introVidContainer'
  // 'playsinline' should support background video in mobile platforms, please confirm
  return (
    <div className={styles}>
      <video ref={vidRef} src={introVidURL} onClick={() => handlePlayVideo()} playsInline autoPlay loop muted />
    </div>
  );
};
