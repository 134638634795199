import "./shopItemPopulationLoader.css";

const ShopItemPopulationLoader = () => {
    return (
        <>
            <div className="shopItemPopulationLoaderContainer">
                <div className="shopItemPopulationLoadersky"></div>
                <div className="shopItemPopulationLoaderSun"></div>
                <div className="shopItemPopulationLoaderFlare1"></div>
                <div className="shopItemPopulationLoaderFlare2"></div>
                <div className="shopItemPopulationLoaderFlare3"></div>
                
                <div className="shopItemPopulationLoaderSmallFlare1"></div>
                <div className="shopItemPopulationLoaderSmallFlare2"></div>
                <div className="shopItemPopulationLoaderSmallFlare3"></div>
                <div className="shopItemPopulationLoaderSmallFlare4"></div>
                <div className="shopItemPopulationLoaderSmallFlare5"></div>
                <div className="shopItemPopulationLoaderSmallFlare6"></div>
                {/* <div className="shopItemPopulationLoaderSmallFlare7"></div> */}
                <div className="shopItemPopulationLoaderSmallFlare8"></div>
                <div className="shopItemPopulationLoaderSmallFlare9"></div>
                <div className="shopItemPopulationLoaderSmallFlare10"></div>
                <div className="shopItemPopulationLoaderOh"></div>
                <div className="shopItemPopulationLoaderMoon"></div>
                <div className="shopItemPopulationLoaderMoon1"></div>
                <div className="loadingHeader">LOADING</div>
            </div>
            
        </>
    );
};

export default ShopItemPopulationLoader;
