import styles from "./Announcement.module.scss";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { StoreItemContext } from "../../context/ItemsContext/ItemsContext";

const Announcement = () => {

    const { storeItems } = useContext(StoreItemContext);
    const location = useLocation();
    const inShop =
        location.pathname.toLowerCase() === "/shop" && storeItems.length > 0
            ? true
            : false;

    const message =
        "We ship weekly on Thursdays";
    let banner = styles.hideBanner;
    if (
        // Date.now() >= new Date(2024, 1, 1) &&
        // Date.now() < new Date(2024, 12, 31) &&
        inShop
    ) {
        banner = styles.showBanner;
    }

    return (
        <>
            <div className={banner}>
                <div className={styles.messageBox}>{message}</div>
            </div>
        </>
    );
};

export default Announcement;
