// we're going to access imagesData with the testData 'props'
// import { imagesData } from "../../data/imagesData";
import { useStoreItemContext } from "../../context/ItemsContext/ItemsContext";
import QuantitySelector from "../QuantitySelector/QuantitySelector";
import "./cart.css";
import { useCartAdjust } from "./useCartAdjust";

const Cart = () => {
    const { storeItems } = useStoreItemContext();

    const {
        customCart,
        incrementQuantity,
        decrementQuantity,
        removeItem,
        setIsCheckout,
        handleDisplayCart,
        displayCart,
    } = useCartAdjust();

    const subTotal = customCart.reduce((acc, cur) => {
        return acc + +cur.price;
    }, 0);
    const removeItemHandler = (id) => {
        removeItem(id);
    };
    const checkoutHandler = (event, error) => {
        if (error) {
            //end checkout loading animation if error occurs
            event.target.animate([{ color: "inherit" }, { color: "red" }], {
                duration: 200,
                iterations: 2,
            });
            return;
        }
        setIsCheckout(true);
    };

    const stockErrorMsg = (quantity, inventory) => {
        if (quantity > inventory) return `only ${inventory} remaining`;

        return null;
    };

    const renderCartItems = () => {
        let isError = false;
        const items = customCart.map((cartItem, idx) => {
            const item = storeItems.find((item) => item.id === cartItem.id);
            if (!item) {
                return null;
            }
            const { name, weight, unit, image } = item;
            const { id: itemId, quantity, inventory, price } = cartItem;

            const cartErrorMessage = stockErrorMsg(quantity, inventory);
            if (cartErrorMessage) isError = true;

            return (
                <div
                    key={idx}
                    className={cartErrorMessage ? "cartItemError" : "cartItem"}
                >
                    <div className="cartItemContent">
                        <div className="cartItemStatsContainer">
                            <div className="cartItemTitleContainer">
                                <div className="cartItemName">
                                    {name.toUpperCase()}
                                </div>
                            </div>
                            <div className="cartItemPrice">
                                {/* updated to **null** format if weight is non-existent */}
                                ${price} {weight !== null && "/"} {weight}
                                {unit}
                            </div>
                            <div className="cartItemEditorContainer">
                                <QuantitySelector
                                    handeDecrement={() =>
                                        decrementQuantity(itemId)
                                    }
                                    handleIncrement={() => {
                                        incrementQuantity(itemId);
                                    }}
                                    quantity={quantity}
                                    size="small"
                                />
                                <div className="cartErrorMessage">
                                    {cartErrorMessage}
                                </div>
                            </div>
                        </div>
                        <div className="removeAndImageContainer">
                            <div
                                className="cartRemoveItem"
                                onClick={() => removeItemHandler(itemId)}
                            >
                                {"remove"}
                            </div>
                            <div className="cartItemImageContainer">
                                <img src={image} alt="item" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
        return [items, isError];
    };

    const [cartItems, error] = renderCartItems();

    const cartItemsContent = () => {
        return (
            <>
                <div className="cartHeaderContainer">
                    <div className="cartHeader">{"CART"}</div>
                    <div
                        className="cartHeaderCloseButton"
                        onClick={() => displayCart && handleDisplayCart()}
                    >
                        <div className="cartHeaderCloseButtonLeftLine"></div>
                        <div className="cartHeaderCloseButtonRightLine"></div>
                    </div>
                </div>
                <div className="cartScrollWrapper">{cartItems}</div>
                {subTotal ? (
                    <>
                        {/* DISABLED - use this to display banner for free shipping over set price (currently $150) */}
                        {/* {subTotal <= 150 &&
              <div className="cartShippingDiscount">orders over $150 sub-total get free shipping</div>
            } */}
                        <div className="cartSubTotalContainer">
                            <div className="cartSubTotal">
                                {"subtotal".toUpperCase()} ${subTotal}
                            </div>
                            <div className="cartSubTotalControls">
                                <div
                                    className="cartToCheckout"
                                    // checkout button now goes to checkout section
                                    onClick={(e) => {
                                        checkoutHandler(e, error);
                                    }}
                                >
                                    {"checkout".toUpperCase()}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="cartSubTotalContainer">
                        <div className="cartSubTotal">cart is empty</div>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {displayCart && (
                <div
                    className="cartOffClickWrapper"
                    onClick={() => handleDisplayCart()}
                ></div>
            )}
            {displayCart && (
                <div className="cartModal">
                    <div>{cartItemsContent()}</div>
                </div>
            )}
        </>
    );
};

export default Cart;
