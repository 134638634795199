import { useState } from "react";
import {
  addCartItem,
  useCartContext,
} from "../../context/CartContext/CartContext";
import { useStoreItemContext } from "../../context/ItemsContext/ItemsContext";
import QuantitySelector from "../QuantitySelector/QuantitySelector";
import "./focusModalForm.css";

const FocusModalForm = ({ id, handleModalFocus }) => {
  const { cart, dispatch } = useCartContext();
  const { storeItems } = useStoreItemContext();

  const [itemQuantity, setItemQuantity] = useState(
    cart.find((item) => item.id === id)?.quantity || 0
  );

  const { inventory } = storeItems.find((item) => item.id === id);

  const handleQuantityChange = (increment) => {
    increment
      ? setItemQuantity((prev) => {
          return itemQuantity < inventory ? prev + 1 : prev;
        })
      : setItemQuantity((prev) => {
          return itemQuantity > 0 ? prev - 1 : prev;
        });
  };

  const increment = () => {
    handleQuantityChange(true);
  };

  const decrement = () => {
    handleQuantityChange(false);
  };

  return (
    <>
      <div className="formContent">
        <div className="quantityLabel">
          {inventory > 3
            ? "QUANTITY"
            : inventory > 0
            ? `LOW STOCK: ONLY ${inventory} LEFT`
            : "OUT OF STOCK"}
        </div>
        <QuantitySelector
          handeDecrement={decrement}
          handleIncrement={increment}
          quantity={itemQuantity}
        />
        <div
          className={`${!itemQuantity ? "submitButton Zero" : "submitButton"}`}
          onClick={() => {
            dispatch(addCartItem(id, itemQuantity));
            handleModalFocus(false);
          }}
        >
          {"update cart".toUpperCase()}
        </div>
      </div>
    </>
  );
};

export default FocusModalForm;
