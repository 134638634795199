import { createContext, useState, useEffect } from "react";

export const SubCategoryContext = createContext();

export const SubCategoryContextProvider = ({ children }) => {
    const [selectedSubCategories, setSelectedSubCategories] = useState(() => ({
        all: true,
    }));

    const handleSubCategory = (subCategory) => {
        setSelectedSubCategories((prev) => {
            if (subCategory === "all") {
                return { all: true };
            }

            return {
                ...prev,
                all: false,
                [subCategory]: !prev[subCategory],
            };
        });
    };

    // this will reset subcategories to 'all' if user deselects all subcategories
    useEffect(() => {
        const booleanExist = Array.from(
            new Set(Object.values(selectedSubCategories))
        );
        if (booleanExist.length === 1 && booleanExist[0] === false) {
            setSelectedSubCategories((prev) => {
                return { all: true };
            });
        }
    }, [selectedSubCategories]);

    const value = {
        handleSubCategory,
        selectedSubCategories,
    };

    return (
        <SubCategoryContext.Provider value={value}>
            {children}
        </SubCategoryContext.Provider>
    );
};
