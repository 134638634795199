import {useRef, useState} from 'react';
import { Cloudinary } from "@cloudinary/url-gen";
import styles from "./styles/AboutPage.module.scss";

export const AboutVideo = () => {

  const [toggle, setToggle] = useState(() => true);
  const vidRef = useRef(null);
  
  const handlePlayVideo = () => {
    setToggle(() => !toggle)
    toggle ? vidRef.current.pause() : vidRef.current.play();
  }

  // Create and configure your Cloudinary instance.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "thejourneyville",
    },
  });

  // creating an accessable instance of the video
  const koanaAboutVid = cld.video("koana/koana_about_vid1");
  // adding an 'auto-quality' filter to adjust dependent on network performance
  koanaAboutVid.quality("auto");

  // creating a URL from the instance
  const koanaAboutVidURL = koanaAboutVid.toURL();

  // adding to JSX surrounded with the styling CSS class 'introVidContainer'
  return (
    <div className={styles.videoContainer}>
      <video ref={vidRef} src={koanaAboutVidURL} onClick={() => handlePlayVideo()} playsInline autoPlay loop muted />
    </div>
  );
};
