import { createContext, useContext } from "react";
import squareApi from "../../lib/squareApi";
import useReducerWithThunk from "../reducerMiddleware";

export const StoreItemContext = createContext();
const initialState = { storeItems: [] };

const types = {
  SET_ITEMS: "SET_ITEMS",
};

const actions = {
  fetchStoreItems: () => async (dispatch) => {
    const storeItems = await squareApi.items.fetchItems();
    dispatch(actions.setItems(storeItems));
  },
  setItems: (items) => ({ type: types.SET_ITEMS, payload: items }),
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_ITEMS:
      return { ...state, storeItems: action.payload };
    default:
      return state;
  }
};

export const StoreItemProvider = ({ children }) => {
  const [state, dispatch] = useReducerWithThunk(reducer, initialState);

  const value = {
    ...state,
    actions,
    dispatch,
  };

  return (
    <StoreItemContext.Provider value={value}>
      {children}
    </StoreItemContext.Provider>
  );
};
export const useStoreItemContext = () => useContext(StoreItemContext);

export const { fetchStoreItems } = actions;

export default StoreItemProvider;
