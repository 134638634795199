import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { StoreItemContext } from "../../context/ItemsContext/ItemsContext";
import { SubCategoryContext } from "../../context/SubCategoryContext/SubCategoryContext";
import "./subCategoryNav.css";

export const SubCategoryNav = () => {
    const { storeItems } = useContext(StoreItemContext);
    const { handleSubCategory, selectedSubCategories } = useContext(SubCategoryContext);
    const location = useLocation();
    const inShop =
        location.pathname.toLowerCase() === "/shop" && storeItems.length > 0
            ? true
            : false;
    const isVisible = inShop
        ? "subCateogryNavContainer"
        : "hiddenSubCategoryNavContainer";

    const uniqueSubCategories = Array.from(
        new Set(
            storeItems.map((item) => {
                return item.cat;
            })
        )
    );

    uniqueSubCategories.sort().unshift("all");

    const subCategories = uniqueSubCategories.map((item, idx) => {

        let isSelected = null;
        if(selectedSubCategories.hasOwnProperty(item) && selectedSubCategories[item] === true){
            isSelected = "selectedCategory";
        } else {
            isSelected = "unselectedCategory"
        }
       
        return (
            <div
                key={idx}
                className={isSelected}
                onClick={
                    () => handleSubCategory(item)
                }
            >
                {item.replace(/" "/g, "").toLowerCase()}
            </div>
        );
    });

    return (
        <>
            <div className={isVisible}>
                <div className="subCateogories">{subCategories}</div>
            </div>
        </>
    );
};
