import { Routes, Route } from "react-router-dom";
// import { Events } from "./components/Events";
import { OnlineShop } from "./components/OnlineShop";
import { AboutPage } from "./pages/AboutPage";
import { CheckoutPage } from "./pages/CheckoutPage";
import { OrderConfirmationPage } from "./pages/OrderConfirmationPage";
import {
  fetchCartItems,
  useCartContext,
} from "./context/CartContext/CartContext";
import { SubCategoryContextProvider } from "./context/SubCategoryContext/SubCategoryContext";
import Cart from "./components/Cart/Cart.js";
import "./App.css";
import { NavBarNew } from "./layout/NavMenu/NavMenuNew";
import { Footer } from "./layout/NavMenu/Footer";
import { useEffect } from "react";
import {
  fetchStoreItems,
  useStoreItemContext,
} from "./context/ItemsContext/ItemsContext";
import FeaturePage from "./pages/FeaturePage/FeaturePage";

const App = () => {
  const { dispatch: itemDispatch } = useStoreItemContext();
  const { dispatch: cartDispatch } = useCartContext();
  useEffect(() => {
    itemDispatch(fetchStoreItems());
    cartDispatch(fetchCartItems());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SubCategoryContextProvider>
        <NavBarNew />
        {/* <SubCategoryNav /> */}
        <Routes>
          <Route path="/" element={<FeaturePage />} />
          {/*<Route path="EVENTS" element={<Events />} />*/}
          <Route path="about" element={<AboutPage />} />
          <Route path="shop" element={<OnlineShop />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="confirmation" element={<OrderConfirmationPage />} />
        </Routes>
        <Footer />
        <Cart />
      </SubCategoryContextProvider>
    </>
  );
};

export default App;
