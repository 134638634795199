import { useState, useEffect } from "react";
import {
  adjustCartItems,
  toggleCart,
  useCartContext,
  checkout,
} from "../../context/CartContext/CartContext";

export const useCartAdjust = () => {
  const { cart, dispatch, displayCart } = useCartContext();
  const [customCart, setCustomCart] = useState(cart);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);
  useEffect(() => {
    // console.log("CART", cart);
    setCustomCart(cart);
  }, [cart]);
  useEffect(() => {
    // setCustomCart(cart);
    // console.log("isTimerRunning", isTimerRunning, "Cart", cart);
    if (isCheckout && !isTimerRunning) {
      setIsTimerRunning(false);
      dispatch(checkout());
      // console.log("dispatch checkout");
      // window.open(order.payLink, "_self");
      setIsCheckout(false);
    }
    // eslint-disable-next-line
  }, [cart, isCheckout]);

  const adjustCart = () => {
    const deletions = cart
      .filter((lineItem) => {
        const idx = customCart.findIndex(
          (customLineItem) => customLineItem.id === lineItem.id
        );
        return idx === -1;
      })
      .map(({ uid, quantity }) => ({ uid, quantity: String(quantity) }));
    const lineItems = customCart.map(({ uid, quantity }) => ({
      uid,
      quantity: String(quantity),
    }));
    setIsTimerRunning(false);
    dispatch(adjustCartItems(lineItems, deletions));
  };

  useEffect(() => {
    if (!isTimerRunning) return;

    const timeoutId = setTimeout(adjustCart, 2000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [customCart]);

  const quantityChange = (quantityChange) => (id) => {
    const newCart = customCart.map((item) => {
      const updatedQuantity = item.quantity + quantityChange;
      if (
        id === item.id &&
        updatedQuantity >= 1 &&
        updatedQuantity <= item.inventory
      ) {
        return {
          ...item,
          quantity: updatedQuantity,
          price: (item.price / item.quantity) * updatedQuantity,
        };
      }
      return item;
    });
    setCustomCart(newCart);
    setIsTimerRunning(true);
  };
  const removeItem = (id) => {
    const newCart = customCart.filter((item) => item.id !== id);
    setCustomCart(newCart);
    setIsTimerRunning(true);
  };
  const incrementQuantity = quantityChange(1);
  const decrementQuantity = quantityChange(-1);

  const handleDisplayCart = () => {
    dispatch(toggleCart());
  };

  return {
    customCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    setIsCheckout,
    handleDisplayCart,
    displayCart,
  };
};
