import { useState, useContext } from "react";
import { CartContext } from "../../context/CartContext/CartContext";
import { SubCategoryContext } from "../../context/SubCategoryContext/SubCategoryContext";
import { StoreItemContext } from "../../context/ItemsContext/ItemsContext";
import { SubCategoryNav} from "../../layout/CategoryNav/SubCategoryNav";
import FocusModal from "../FocusModal/FocusModal";
import ShopItemPopulationLoader from "../Loaders/ShopItemPopulationLoader";
import "./onlineShopNew.css";

export const OnlineShop = () => {
    const { storeItems } = useContext(StoreItemContext);
    const { displayCart } = useContext(CartContext);
    const { selectedSubCategories } = useContext(SubCategoryContext);
    const [modalFocus, setModalFocus] = useState(false);
    const [currentModalId, setCurrentModalId] = useState(0);

    const handleModalFocus = (isOpen) => {
        setModalFocus(isOpen);
    };

    // console.log(storeItems)
    const isLoading = storeItems.length === 0;
    const products = storeItems.map((item, idx) => {
        // this line is matching each item
        const { inventory } = item;
        const { cat: category } = item;
        if (
            selectedSubCategories["all"] ||
            (selectedSubCategories.hasOwnProperty(category) &&
                selectedSubCategories[category] === true)
        ) {
            return (
                <div
                    key={item.id + idx}
                    className={
                        modalFocus || displayCart
                            ? "itemCardBackground"
                            : inventory <= 0
                            ? "itemCardNoStock"
                            : "itemCard"
                    }
                    onClick={() => {
                        handleModalFocus(!modalFocus && inventory > 0);
                        setCurrentModalId(item.id);
                    }}
                >
                    <img id="itemImage" src={item.image} alt={item.name}></img>
                    <div className="itemInfo">
                        <div className="itemDesc">
                            {item.name.toUpperCase()}
                        </div>
                        <div className="itemSpecs">
                            {inventory > 5 ? (
                                <>
                                    <div className="itemPrice">
                                        {`$${item.price}`}&nbsp;
                                        <span className="itemWeight">
                                            {item.weight
                                                ? `${item.weight}${item.unit}`
                                                : null}
                                        </span>
                                    </div>
                                </>
                            ) : inventory > 0 ? (
                                <>
                                    <div className="itemPrice">{`$${item.price}`}</div>
                                    <div className="itemWeight">
                                        {item.weight
                                            ? `${item.weight}${item.unit}`
                                            : null}
                                    </div>
                                    <div className="lowStock">{` only ${inventory} in stock`}</div>
                                </>
                            ) : inventory <= 0 ? (
                                <>
                                    <div className="itemPrice">{`$${item.price}`}</div>
                                    <div className="itemWeight">
                                        {item.weight
                                            ? `${item.weight}${item.unit}`
                                            : null}
                                    </div>
                                    <div className="outOfStock">{`sold out`}</div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            );
        }
    });

    if (isLoading) return <ShopItemPopulationLoader />;

    return (
        <>
            {modalFocus && (
                <>
                    <div
                        onClick={() => handleModalFocus(false)}
                    ></div>
                    <FocusModal
                        modalFocus={modalFocus}
                        handleModalFocus={handleModalFocus}
                        id={currentModalId}
                    />
                </>
            )}
            {/* spacer is to push down the item cards for the announcement */}
            <div className="spacer"></div>
            <SubCategoryNav />
            <div className="itemCardsContainer">
                <div className="itemCards">{products}</div>
            </div>
        </>
    );
};

export default OnlineShop;
