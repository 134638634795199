import styles from "./Footer.module.scss";

const Footer = () => {
    return <div className={styles.container}>©KOANA</div>;
};
export default Footer;

// const Footer = () => {
//   return (
//       <div className={styles.container}>
//           <div className={styles.spacer}></div>
//           <div>© KOANA 2022</div>
//       </div>
//   );
// };
