import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { koana_logo } from "../../../assets/images/icons";
import { GetIcon } from "../GetIcon";
import {
  toggleCart,
  useCartContext,
} from "../../../context/CartContext/CartContext";
import styles from "./SmScreenMenu.scss";

const SmScreenMenu = () => {
  const [expand, setExpand] = useState(false);
  const navigate = useNavigate();
  const darkThemes = ["/", "/about"];
  const location = useLocation();
  const isDark = darkThemes.some((path) => path === location.pathname);
  const { dispatch } = useCartContext();

  const handleDisplayCart = () => {
    dispatch(toggleCart());
  };

  const menuBtnRef = useRef(null);

  // the following useEffect hook is used to close the vertical menu when
  // the user clicks off of the menu
  useEffect(() => {
    const outsideClick = document.querySelector("html");
    const closeVerticalMenu = (e) => {
      if (!menuBtnRef.current.contains(e.target) && expand) {
        setExpand(false);
      }
    };

    outsideClick.addEventListener("click", closeVerticalMenu);

    return () => {
      outsideClick.removeEventListener("click", closeVerticalMenu);
    };
  }, [expand]);

  const burgerCollapsedKoanaIcon = (
    <div key={"koanaIcon"} className="burgerCollapsedKoanaIcon">
      <img
        onClick={() => {
          navigate(`/`);
        }}
        src={koana_logo}
        alt="logo"
      />
    </div>
  );

  const koanaIcon = (
    <div key={"koanaIcon"} className="koanaIcon">
      <img
        onClick={() => {
          navigate(`/`);
        }}
        src={koana_logo}
        alt="logo"
      />
    </div>
  );

  //const tradNavItems = ["EVENTS", "shop", "about"].map((item, idx) => (
  // EVENTS removed below 
  const tradNavItems = ["shop", "about"].map((item, idx) => (
    <div key={`${idx}${item}`}>
      <div className={item !== "EVENTS" ? "tradNavItems" : "tradNavItemsEvents"} onClick={() => navigate(`/${item}`)}>
        {item}
      </div>
    </div>
  ));

  const hiddenStyle = expand
    ? "burgerNavItems"
    : "burgerNavItems burgerNavItemsHide";
  const classStyle = isDark
    ? hiddenStyle + " darkTheme"
    : hiddenStyle + " lightTheme";
  return (
    <div className={styles.container}>
      <div className="bigMacMode">
        <div
          className="bigMacIcon"
          onClick={(e) => {
            e.stopPropagation();
            setExpand((prev) => !prev);
          }}
        >
          {expand ? (
            <>
              <div className="burgerSpacer"></div>
              <div className="burger burgerFade"></div>
              <div className="burger burgerExpand"></div>
              <div className="burger burgerFade"></div>
            </>
          ) : (
            <>
              <div className="burgerSpacer"></div>
              <div className="burger"></div>
              <div className="burger"></div>
              <div className="burger"></div>
            </>
          )}
        </div>

        {!expand && (
          <>
            <div className="collapedFiller"></div>
            {/* small responsive menu EVENT menu item removed below*/}
            {/* <div
              className="tradNavItemsCollapsedEvents"
              onClick={() => navigate(`/${"EVENTS"}`)}
            >
              EVENTS
            </div> */}
            <div
              className="tradNavItemsCollapsed"
              onClick={() => navigate(`/${"SHOP"}`)}
            >
              shop
            </div>
            <div className="collapsedCart">
              <GetIcon
                iconsData={[[faCartArrowDown, () => handleDisplayCart()]]}
              />
            </div>
            <div>{burgerCollapsedKoanaIcon}</div>
          </>
        )}
        <div className={classStyle} ref={menuBtnRef}>
          {koanaIcon}
          {tradNavItems}
          <GetIcon
            iconsData={[
              [faCartArrowDown, () => handleDisplayCart()],
              [
                faInstagram,
                () =>
                  window.open(
                    "https://www.instagram.com/koanahawaii/",
                    "_blank"
                  ),
              ],
            ]}
          />
        </div>
      </div>
    </div>
  );
};
export default SmScreenMenu;
