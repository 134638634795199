import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCartContext } from "../../context/CartContext/CartContext";
import "./cartBadge.css";

const CartBadge = ({ quantity, action }) => {
  const state = useCartContext();
  const { isLoading } = state;

  const content = isLoading ? (
    <FontAwesomeIcon spin icon={faSpinner} />
  ) : (
    quantity
  );

  return (
    quantity > 0 && (
      <div className="cartBadgeContainer" onClick={() => action()}>
        <div className="cartBadgeNumber">{content}</div>
      </div>
    )
  );
};

export default CartBadge;
